import React from 'react'; // Corrected import
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Header from './compontens/Header';
import Footer from './compontens/Footer';
import Popup from './compontens/Popup';
import Home from './pages/Home';
import Rmc from './pages/Rmc';
import GaneshCP from './pages/GaneshCP';
import GaneshBS from './pages/GaneshBS'; // Fixed the import for 'GaneshBS'

// Dynamically importing components
const UserLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

const AdminLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
}

const App = () => {
  return (
    <Router>
      <div>
        <Popup />
        <Routes>
          {/* userLayout */}
          <Route path="/" element={<UserLayout />}>
            <Route index element={<Home />} />
            <Route path='/sairam_rmc' element={<Rmc />} />
            <Route path='/sai_ganesh_concrete_products' element={<GaneshCP />} />
            <Route path='/sai_ganesh_blue_sand' element={<GaneshBS />} />
          </Route>

          {/* AdminLayout */}
          <Route path='/admin' element={<AdminLayout />}>
            {/* Add your admin routes here */}
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
