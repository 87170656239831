import React, { useEffect } from 'react'
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


import FAQ from '../compontens/FAQ'
import ContactForm from '../compontens/ContactForm'


import videobanner from '../images/banner/video.mp4'
import background from '../images/rmc/background.jpg'
import banner from '../images/blue_sand/banner.jpg'

import about from '../images/blue_sand/about.jpg'

import iconOne from '../images/blue_sand/icon/one.png'
import iconTwo from '../images/blue_sand/icon/two.png'
import iconThree from '../images/blue_sand/icon/three.png'
import iconFour from '../images/blue_sand/icon/four.png'
import iconFive from '../images/blue_sand/icon/five.png'
import iconSix from '../images/blue_sand/icon/six.png'
import iconSeven from '../images/blue_sand/icon/seven.png'
import iconEight from '../images/blue_sand/icon/eight.png'

import serviceOne from '../images/blue_sand/serviceOne.jpg'
import serviceTwo from '../images/blue_sand/serviceTwo.jpg'
import serviceThree from '../images/blue_sand/serviceThree.jpg'
import serviceFour from '../images/blue_sand/serviceFour.jpg'
import serviceFive from '../images/blue_sand/serviceFive.jpg'
import serviceSix from '../images/blue_sand/serviceSix.jpg'



import imgOne from '../images/blue_sand/gallery/1.jpg'
import imgTwo from '../images/blue_sand/gallery/2.jpg'
import imgThree from '../images/blue_sand/gallery/3.jpg'
import imgFour from '../images/blue_sand/gallery/4.jpg'
import imgFive from '../images/blue_sand/gallery/5.jpg'
import imgSix from '../images/blue_sand/gallery/6.jpg'
import imgSeven from '../images/blue_sand/gallery/7.jpg'
import imgEight from '../images/blue_sand/gallery/8.jpg'
import imgNine from '../images/blue_sand/gallery/9.jpg'
import imgTen from '../images/blue_sand/gallery/10.jpg'


import clientOne from '../images/rmc/port/clientOne.png'


import contact from '../images/rmc/contact.png'


const GaneshBS = () => {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const settingsOne = {
        className: "center",
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 5,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "15px",
                }
            }
        ]
    };


    return (
        <>
            <div className='overflow-hidden'>

                <div>
                    <img src={background} className='fixed top-0 w-full h-screen -z-30 object-cover' alt="" />
                </div>

                <div>
                    <div className='fixed top-0 w-full h-screen -z-20 object-cover bg-black/85'></div>
                </div>

                {/* ----- banner ----- */}

                <section>
                    <div>

                        {/* <video className='w-full object-cover ' autoPlay muted loop playsInline >
                        <source src={videobanner} type="video/mp4" />
                    </video> */}
                        <div>
                            <img src={banner} className='w-full object-cover' alt='banner' />
                        </div>

                    </div>
                </section>

                {/* ------- About ------ */}

                <section className='bg-[#ebecf0] font-[poppins]' id='about' >

                    <div className='pt-7 md:pt-14 pb-10 md:pb-14  font-[]'>

                        <div className='flex flex-col-reverse md:flex-row items-center gap-y-4 gap-x-20 px-4 md:px-0 w-full md:w-[80%] mx-auto'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center items-center' data-aos='fade-right' data-aos-duration="2000" >
                                <div className='w-full flex justify-center'>
                                    <img src={about} className='w-full md:w-[75%] object-cover rounded-md drop-shadow-lg' alt="" />
                                </div>
                            </div>

                            <div className='w-full md:w-[50%]' data-aos='fade-left'>

                                <h3 className='font-bold text-[14px] sm:text-[14px]  md:text-[16px] uppercase  text-start text-sky-700' data-aos='fade-left' data-aos-delay="70" data-aos-duration="2000"  > About US </h3>
                                <h3 className='font-bold text-[18px] sm:text-[22px]  md:text-[30px] uppercase text-gray-600 text-start' data-aos='fade-left' data-aos-delay="70" data-aos-duration="2000">Sai Ganesh Blue Sand </h3>

                                <p className='font-medium mt-2 sm:mt-3 md:mt-5 text-gray-600 text-[12px] sm:text-[14px] md:text-[14px]  leading-6' data-aos='fade-left' data-aos-delay="70" data-aos-duration="3000">
                                    At our company, we specialize in providing high-quality concrete products tailored to meet diverse construction needs. Our offerings include solid blocks, hollow blocks, durable pavers, and expertly installed kerbstones.
                                    We prioritize durability, strength, and aesthetic appeal in all our products, ensuring they enhance any project.
                                </p>
                                <p className='font-medium mt-1 sm:mt-2 md:mt-3 text-gray-600 text-[12px] sm:text-[14px] md:text-[14px] leading-6' data-aos='fade-left' data-aos-delay="70" data-aos-duration="3000">
                                    With a commitment to excellence, our experienced team guarantees reliable solutions for all your construction requirements. Trust us for quality and consistency in every project.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>


                {/* ---------- services -------- */}

                <section className='pt-6 md:pt-14 pb-10 md:pb-14 bg-white font-[poppins]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>


                        <div className='flex flex-col items-center gap-y-1 md:gap-y-3'>
                            <h2 className='text-center font-bold text-[18px] md:text-[28px] uppercase text-sky-700 ' data-aos='fade-up' data-aos-duration='1000'>What We Offer</h2>
                            <p className='text-[11px] md:text-[14px] text-center text-gray-600 w-full md:w-[80%] mx-auto' data-aos='fade-up' data-aos-duration='2000'>Providing top-quality construction materials and services, including ready-mix concrete and crushed stone aggregates. Our team ensures reliable, efficient solutions for all your residential and commercial projects.</p>
                        </div>


                        <div className='flex items-center justify-center mt-4 md:mt-16'>
                            <div className='grid grid-cols-1 md:grid-cols-3  md:gap-x-16  gap-y-8 md:gap-y-16'>


                                <div className='group' data-aos='fade-right' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceOne} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>

                                            <div className='flex flex-col gap-y-3 items-center px-4 '>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>Blue Sand</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-200'>Our solid blocks are made from durable concrete materials that provide strength and stability to any structure.We supply high-quality solid blocks for building foundations, walls, and partitions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='group' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceTwo} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>

                                            <div className='flex flex-col gap-y-3 items-center px-4 '>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>M-Sand</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-200'>Our solid blocks are made from durable concrete materials that provide strength and stability to any structure.We supply high-quality solid blocks for building foundations, walls, and partitions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='group' data-aos='fade-left' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceThree} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>

                                            <div className='flex flex-col gap-y-3 items-center px-4 '>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>B-Sand</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-200'>Our solid blocks are made from durable concrete materials that provide strength and stability to any structure.We supply high-quality solid blocks for building foundations, walls, and partitions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>


                {/*-------- advantages  --------*/}

                <section className='font-[poppins] pt-6 md:pt-14 pb-10 md:pb-16 bg-gray-50'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col items-center gap-y-3'>
                            <h2 className='text-center font-bold  text-[16px] md:text-[28px] uppercase text-sky-700' data-aos='fade-up' data-aos-duration='3000'><span className='text-gray-700'>Advantages of </span> Sai Ganesh Blue Sand</h2>
                        </div>

                        <div className='flex justify-center items-center w-full mt-8 md:mt-20'>
                            <div className='grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-14 md:gap-y-16 w-full  '>

                                <div className='flex flex-col gap-y-3 items-center' data-aos='fade-right' data-aos-duration='2000'>
                                    <div>
                                        <img src={iconOne} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[12px] md:text-[14px] text-black'>Diverse Service Range</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-3 items-center ' data-aos='fade-right' data-aos-duration='2000'>
                                    <div>
                                        <img src={iconFive} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[12px] md:text-[14px] text-black'>Timely Delivery Services</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-3 items-center ' data-aos='fade-left' data-aos-duration='1000'>
                                    <div>
                                        <img src={iconThree} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[12px] md:text-[14px] text-black'>Customized Stone Products</p>
                                    </div>
                                </div>

                                <div className='flex flex-col gap-y-3 items-center ' data-aos='fade-left' data-aos-duration='2000'>
                                    <div>
                                        <img src={iconFour} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[12px] md:text-[14px] text-black'>Infrastructure Project Support</p>
                                    </div>
                                </div>

                                <div className='flex flex-col gap-y-3 items-center ' data-aos='fade-right' data-aos-duration='1000'>
                                    <div>
                                        <img src={iconSix} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[12px] md:text-[14px] text-black'>Cost-Effective Solutions</p>
                                    </div>
                                </div>
                                
                                <div className='flex flex-col gap-y-3 items-center ' data-aos='fade-left' data-aos-duration='2000'>
                                    <div>
                                        <img src={iconEight} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[12px] md:text-[14px] text-black'>Consultation Services</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-3 items-center ' data-aos='fade-left' data-aos-duration='1000'>
                                    <div>
                                        <img src={iconSeven} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[12px] md:text-[14px] text-black'>Long-Term Supply Contracts</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-3 items-center' data-aos='fade-right' data-aos-duration='1000'>
                                    <div>
                                        <img src={iconTwo} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[12px] md:text-[14px] text-black'>Construction Material Supply</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>

                {/* ----------- Project Gallery ---------- */}

                <section className='pt-6 md:pt-14  pb-10 md:pb-16 bg-sky-100 font-[poppins]' id='gallery'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col gap-y-1 md:gap-y-3'>
                            <h2 className='font-bold text-[20px] md:text-[28px] text-center uppercase text-sky-700 ' data-aos='fade-right' data-aos-duration='1000'>Project Gallery</h2>
                            <p className='text-[10px] md:text-[14px] text-center text-gray-700 font-[roboto]' data-aos='fade-left' data-aos-duration='2000'>Explore our diverse portfolio of completed projects, showcasing our expertise in delivering high-quality construction solutions. From residential buildings to
                                commercial structures, each project highlights our commitment to excellence, precision, and customer satisfaction.
                            </p>
                        </div>

                        <div className='flex justify-center items-center mt-6 md:mt-10'>

                            <div className='grid grid-cols-3 md:grid-cols-5 gap-x-3  md:gap-x-4  gap-y-3 md:gap-y-4'>

                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgOne} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgTwo} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgThree} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgFour} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl relative'>
                                        <img src={imgFive} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgSix} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgSeven} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgEight} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgNine} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgTen} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                {/* ----- FAQ ------ */}

                {/* <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-sky-950'>
                    <div>
                        <FAQ />
                    </div>
                </section> */}


                {/* --------- Our Clients -----------*/}

                <section className='pt-6 md:pt-20  pb-10 md:pb-20 bg-gray-50 font-[poppins]' >
                    <div className='w-full px-4 md:w-[90%] mx-auto'>

                        <div className='mb-4 flex flex-col gap-y-1 md:gap-y-3 '>
                            <h1 className='uppercase text-[18px] md:text-[28px] font-bold text-center text-sky-700 ' data-aos='fade-up'>Our Clients</h1>
                            <p className='md:font-semibold  text-[12px] md:text-[14px]  text-justify md:text-center font-[roboto] w-full md:w-[85%] mx-auto' data-aos='fade-up' >We are proud to work with a diverse range of clients across industries, from residential homeowners to large commercial enterprises. Their trust in our expertise and commitment to quality is what drives us to deliver outstanding results on every project.</p>
                        </div>


                        <div className="slider-container">
                            <Slider {...settingsOne} className='px-4 py-4 md:py-8'>

                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>
                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>
                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>
                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>
                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>

                            </Slider>
                        </div>

                    </div>
                </section>




                {/* ---------- contact ---------- */}

                <section className='bg-white py-6 md:py-10' id='contact' >
                    <div className='flex flex-col  px-4 justify-center items-center font-[poppins]'>

                        <div>
                            <h3 className='font-bold text-[20px] sm:text-[24px] md:text-[30px] text-center md:tracking-[2px] text-sky-700 uppercase' data-aos='fade-up'>Get in Touch</h3>
                            <p className='w-full md:w-[80%] mx-auto text-center text-[10px] md:text-[14px]' data-aos='fade-up'>Have questions or need assistance? Contact us at Sri SaiRam for expert support and information about our construction services. We're here to help you with your project needs.</p>
                        </div>

                        <div className='flex flex-col md:flex-row items-start justify-center w-full   md:w-[85%]  mx-auto gap-y-10 gap-x-6 mt-6 md:mt-12'>


                            <div className='w-full flex flex-col  justify-center items-start md:w-[50%] mx-auto' data-aos='fade-right'>

                                <div className='w-full'>
                                    <img src={contact} className='w-[85%] mx-auto object-cover' alt='image' />
                                </div>

                            </div>


                            <div className='w-full md:w-[50%] mx-auto' data-aos='fade-left'>
                                <div className='flex justify-center items-center w-full'>

                                    <div className="  rounded-lg  w-[100%] max-w-md">
                                        <ContactForm />
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            </div>
        </>
    )
}

export default GaneshBS