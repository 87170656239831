import React, { useEffect } from 'react'
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import FAQ from '../compontens/FAQ'
import ContactForm from '../compontens/ContactForm'

import logo from '../images/black.png'


import videobanner from '../images/rmc/vid.mp4'
import background from '../images/rmc/background.jpg'

import about from '../images/rmc/rmc_about.png'


import iconOne from '../images/rmc/icon/one.png'
import iconTwo from '../images/rmc/icon/two.png'
import iconThree from '../images/rmc/icon/three.png'
import iconFour from '../images/rmc/icon/four.png'
import iconFive from '../images/rmc/icon/five.png'
import iconSix from '../images/rmc/icon/six.png'
import iconSeven from '../images/rmc/icon/seven.png'
import iconEight from '../images/rmc/icon/eight.png'

import serviceOne from '../images/rmc/serviceOne.jpg'
import serviceTwo from '../images/rmc/serviceTwo.jpg'
import serviceThree from '../images/rmc/serviceThree.jpg'
import serviceFour from '../images/rmc/serviceFour.jpg'
import serviceFive from '../images/rmc/serviceFive.jpg'
import serviceSix from '../images/rmc/serviceSix.png'


import imgOne from '../images/banner/7.jpg'
import imgTwo from '../images/banner/8.jpg'
import imgThree from '../images/banner/9.jpg'
import imgFour from '../images/banner/10.jpg'

import clientOne from '../images/rmc/port/clientOne.png'

import contact from '../images/rmc/contact.png'


const Rmc = () => {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const settingsOne = {
        className: "center",
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 5,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "15px",
                }
            }
        ]
    };


    return (
        <>

            <div className='overflow-hidden'>

                <div>
                    <img src={background} className='fixed top-0 w-full h-screen -z-30 object-cover' alt="" />
                </div>

                <div>
                    <div className='fixed top-0 w-full h-screen -z-20 object-cover bg-black/85'></div>
                </div>

                {/* ----- banner ----- */}

                <section>
                    <div>

                        <video className='w-full object-cover ' autoPlay muted loop playsInline >
                            <source src={videobanner} type="video/mp4" />
                        </video>

                    </div>
                </section>

                {/* ------- About ------ */}

                <section className='bg-[#ebecf0] font-[poppins]' id='about' >

                    <div className='pt-7 md:pt-14 pb-10 md:pb-14  font-[]'>

                        <div className='flex flex-col-reverse md:flex-row items-center gap-y-4 gap-x-20 px-4 md:px-0 w-full md:w-[80%] mx-auto'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center items-center' data-aos='fade-right' data-aos-duration="2000" >
                                <div className='w-full flex justify-center'>
                                    <img src={about} className='w-full md:w-[90%] object-cover rounded-md drop-shadow-lg' alt="" />
                                </div>
                            </div>

                            <div className='w-full md:w-[50%]' data-aos='fade-left'>

                                <h3 className='font-bold text-[14px] sm:text-[14px]  md:text-[16px] uppercase  text-start text-sky-700' data-aos='fade-left' data-aos-delay="70" data-aos-duration="2000"  > About US </h3>
                                <h3 className='font-bold text-[23px] sm:text-[22px]  md:text-[30px] uppercase text-gray-600 text-start' data-aos='fade-left' data-aos-delay="70" data-aos-duration="2000"  > Sri SaiRam RMC </h3>

                                <p className='font-medium mt-2 sm:mt-3 md:mt-5 text-gray-600 text-[12px] sm:text-[14px] md:text-[14px] text-justify leading-6' data-aos='fade-left' data-aos-delay="70" data-aos-duration="3000">
                                    At Sri SaiRam Ready Mix Concrete, we provide high-quality ready-mix concrete solutions for all types of construction projects. Our mixes are designed for strength, durability, and precision, ensuring success in every build.
                                    With dedicated staff, Sri Sairam aims to expand its offerings and serve a wider clientele. Conveniently located near Biligiri Granites, it’s easily accessible for local and visiting customers.
                                </p>
                            
                            </div>
                        </div>
                    </div>
                </section>




                {/* ---------- services -------- */}

                <section className='pt-6 md:pt-14 pb-10 md:pb-14 bg-[#F2F0EF] font-[poppins]' id='services'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>


                        <div className='flex flex-col items-center gap-y-1 md:gap-y-3'>
                            <h2 className='text-center font-bold text-[18px] md:text-[28px] uppercase text-sky-700 ' data-aos='fade-up' data-aos-duration='1000'>What We Offer</h2>
                            <p className='text-[10px] md:text-[14px] text-center text-gray-600 w-full md:w-[85%] mx-auto' data-aos='fade-up' data-aos-duration='2000'>We provide a comprehensive range of ready-mix concrete solutions tailored to meet the diverse needs of our clients. From residential projects to large-scale infrastructure, we deliver the best concrete solutions to ensure the success of your construction ventures.</p>
                        </div>


                        <div className='flex items-center justify-center mt-4 md:mt-16'>
                            <div className='grid grid-cols-1 md:grid-cols-3 md:gap-x-16 gap-y-8 md:gap-y-16'>
                                {/* Card 1 */}
                                <div className='group' data-aos='fade-right' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceOne} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>
                                            <div className='flex flex-col gap-y-3 items-center px-4'>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>M7.5 Concrete Mix</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-200'>Ideal for simple construction tasks like PCC (Plain Cement Concrete) works, foundation beddings, and flooring where low strength is sufficient.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Card 2 */}
                                <div className='group' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceTwo} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>
                                            <div className='flex flex-col gap-y-3 items-center px-4'>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>M10 Concrete Mix</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-200'>M10 Grade Concrete is a nominal mix widely used for non-structural applications where low-strength concrete is required. It provides a characteristic compressive strength of 10 MPa after 28 days of curing.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Card 3 */}
                                <div className='group' data-aos='fade-left' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceThree} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>
                                            <div className='flex flex-col gap-y-3 items-center px-4'>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>M15 Concrete Mix</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-300'>It is a nominal mix used for structural and non-structural purposes where moderate strength is required.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Card 4 */}
                                <div className='group' data-aos='fade-right' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceFour} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>
                                            <div className='flex flex-col gap-y-3 items-center px-4'>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>M20 Concrete Mix</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-300'>It is known for its moderate strength, durability, and versatility. Offers a good balance between workability and strength, making it suitable for a variety of construction tasks.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Card 5 */}
                                <div className='group' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceSix} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>
                                            <div className='flex flex-col gap-y-3 items-center px-4'>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>M25 Concrete Mix</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-300'>High-strength mix often used for reinforced concrete structures. It provides greater durability and strength, making it ideal for structural applications in both residential and commercial projects.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Card 6 */}
                                <div className='group' data-aos='fade-left' data-aos-duration='2000'>
                                    <div className='h-full flex flex-col drop-shadow-md shadow-md bg-white border border-sky-700 justify-between items-center pb-8 rounded-md overflow-hidden duration-300 group-hover:bg-sky-900'>
                                        <div className='flex-1 flex flex-col justify-start gap-y-6'>
                                            <div>
                                                <img src={serviceFive} className='w-full h-48 object-cover group-hover:scale-105 group-hover:border-2 group-hover:border-white duration-300' alt='none' />
                                            </div>
                                            <div className='flex flex-col gap-y-3 items-center px-4'>
                                                <h2 className='font-bold text-[18px] md:text-[20px] text-center text-sky-700 group-hover:text-white duration-300'>M30 Concrete Mix</h2>
                                                <p className='text-[12px] md:text-[14px] text-justify font-[roboto] text-gray-900 group-hover:text-gray-300'>The higher compressive strength of M30 grade concrete makes it suitable for projects that require greater load-bearing capacity and durability. It is often chosen for structural elements and projects subjected to heavier loads.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


                {/*-------- advantages  --------*/}

                <section className='font-[poppins] pt-6 md:pt-14 pb-10 md:pb-14 bg-gray-100'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col items-center gap-y-3'>
                            <h2 className='text-center font-bold  text-[16px] md:text-[28px] uppercase text-sky-700' data-aos='fade-up' data-aos-duration='3000'> <span className='text-gray-600'>Advantages of</span>  Sri SaiRam RMC Concrete</h2>
                        </div>

                        <div className='flex justify-center items-center w-full mt-8 md:mt-20'>
                            <div className='grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-8 md:gap-y-16 w-full  '>

                                <div className='flex flex-col gap-y-1 md:gap-y-3 items-center' data-aos='fade-right' data-aos-duration='2000'>
                                    <div>
                                        <img src={iconOne} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[10px] md:text-[14px] text-black'>Consistent Quality</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-1 md:gap-y-3 items-center' data-aos='fade-right' data-aos-duration='1000'>
                                    <div>
                                        <img src={iconTwo} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[10px] md:text-[14px] text-black'>Time-Saving</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-1 md:gap-y-3 items-center ' data-aos='fade-left' data-aos-duration='1000'>
                                    <div>
                                        <img src={iconThree} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[10px] md:text-[14px] text-black'>Reduced Labor Costs</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-1 md:gap-y-3 items-center ' data-aos='fade-left' data-aos-duration='2000'>
                                    <div>
                                        <img src={iconFour} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[10px] md:text-[14px] text-black'>Efficient Delivery</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-1 md:gap-y-3 items-center ' data-aos='fade-right' data-aos-duration='2000'>
                                    <div>
                                        <img src={iconFive} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[10px] md:text-[14px] text-black'>Customizable Mix</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-1 md:gap-y-3 items-center ' data-aos='fade-right' data-aos-duration='1000'>
                                    <div>
                                        <img src={iconSix} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[10px] md:text-[14px] text-black'>Environmentally Friendly</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-1 md:gap-y-3 items-center ' data-aos='fade-left' data-aos-duration='1000'>
                                    <div>
                                        <img src={iconEight} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[10px] md:text-[14px] text-black'>Improved Safety</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-1 md:gap-y-3 items-center ' data-aos='fade-left' data-aos-duration='2000'>
                                    <div>
                                        <img src={iconSeven} className='w-16 md:w-28 object-cover drop-shadow-lg' />
                                    </div>
                                    <div>
                                        <p className='font-semibold text-center text-[10px] md:text-[14px] text-black'>Enhanced Workability</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                {/* ----------- Project Gallery ---------- */}

                <section className='pt-6 md:pt-14  pb-10 md:pb-16 bg-blue-100 font-[poppins]' id='gallery'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col gap-y-1 md:gap-y-3'>
                            <h2 className='font-bold text-[20px] md:text-[28px] text-center uppercase text-sky-600' data-aos='fade-right' data-aos-duration='1000'>Project Gallery</h2>
                            <p className='text-[10px] md:text-[14px] text-center text-gray-700 font-[roboto]' data-aos='fade-left' data-aos-duration='2000'>
                                we have had the privilege of working on some of the most exciting and challenging projects in the industry. Our team of experts has successfully delivered high-quality results on time and on budget.Our commitment to quality, safety, and customer satisfaction has earned us a reputation as a trusted partner in the industry.
                                Here are some of our successful projects:
                            </p>
                        </div>

                        <div className='flex justify-center items-center mt-6 md:mt-10'>

                            <div className='grid grid-cols-3 md:grid-cols-5 gap-x-3  md:gap-x-4  gap-y-3 md:gap-y-4'>

                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgOne} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgTwo} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgThree} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgFour} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl relative'>
                                        <img src={imgOne} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgTwo} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgThree} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgFour} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgOne} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center ' data-aos='fade-up' data-aos-duration='2000'>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgTwo} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>




                {/* --------- Our Clients -----------*/}

                <section className='pt-6 md:pt-20  pb-10 md:pb-20 bg-gray-50 font-[poppins]' >
                    <div className='w-full px-4 md:w-[90%] mx-auto'>

                        <div className='mb-4 flex flex-col gap-y-1 md:gap-y-3 '>
                            <h1 className='uppercase text-[18px] md:text-[28px] font-bold text-center text-gray-700' data-aos='fade-up'>Our Clients</h1>
                            <p className='md:font-semibold  text-[12px] md:text-[14px]  text-justify md:text-center font-[roboto] w-full md:w-[85%] mx-auto' data-aos='fade-up' >We have built strong relationships with our clients by understanding their unique needs and providing customized solutions. We are proud to serve our clients and look forward to continuing our partnership with them.</p>
                        </div>


                        <div className="slider-container">
                            <Slider {...settingsOne} className='px-4 py-4 md:py-8'>

                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>
                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>
                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>
                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>
                                <div className='px-2 md:px-5' data-aos='fade-up' data-aos-duration="1000">
                                    <div className='group relative overflow-hidden border md:border-2 border-gray-500  rounded-lg'>
                                        <img src={clientOne} className='w-full drop-shadow-lg object-cover  group-hover:scale-105 duration-300' alt="" />
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </section>


                {/* ---------- contact ---------- */}

                <section className='bg-white py-5 md:py-10' id='contact' >
                    <div className='flex flex-col  px-4 justify-center items-center font-[poppins]'>

                        <div>
                            <h3 className='text-[20px] sm:text-[24px] md:text-[30px] text-center md:tracking-[2px] text-sky-700 font-bold uppercase ' data-aos='fade-up'>Get in Touch</h3>
                            <p className='w-full md:w-[80%] mx-auto text-center text-[10px] md:text-[14px]' data-aos='fade-up'>We're here to help you with all your concrete needs. Whether you have a question about our products, services, or want to place an order, we're just a call or click away</p>
                        </div>

                        <div className='flex flex-col md:flex-row items-start justify-center w-full   md:w-[85%]  mx-auto gap-y-10 gap-x-6 mt-6 md:mt-12'>


                            <div className='w-full flex flex-col  justify-center items-start md:w-[50%] mx-auto' data-aos='fade-right'>

                                <div className='w-full flex justify-center items-center'>
                                    <img src={contact} className='w-[85%] mx-auto object-cover' alt='image' />
                                </div>

                            </div>


                            <div className='w-full md:w-[50%] mx-auto' data-aos='fade-left'>
                                <div className='flex justify-center items-center w-full'>

                                    <div className="  rounded-lg  w-[100%] ">
                                        <ContactForm />
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </section>


            </div>

        </>
    )
}

export default Rmc;

