import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { FaWhatsapp } from "react-icons/fa";
import logo from '../images/white_logo.png';
import black from '../images/black.png';

const Header = () => {

    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);

    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(!nav);
    };

    // Color change based on scroll position
    const [navbarBg, setNavbarBg] = useState('bg-gray-100');
    const [color, setColor] = useState('text-black');

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarBg('bg-white shadow-md'); // Add shadow on scroll
                setColor('text-sky-700');
            } else {
                setNavbarBg('bg-gray-100');
                setColor('text-black');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const topPage = () => {
        window.scroll(0, 0);
    };

    return (
        <>
            <nav className={`w-full sticky top-0 z-30 py-3 md:py-5 ${navbarBg} transition-all duration-300 border-b-2 border-black/20`}>
                <div className="max-w-full mx-auto font-[poppins]">
                    <div className='flex justify-between items-center px-4 sm:px-9 w-full xl:w-[90%] mx-auto'>

                        {/* Logo */}
                        <div className='w-full md:w-fit'>
                            <Link to='/' onClick={topPage}>
                                <img src={logo} className='w-20 sm:w-20 md:w-32 object-cover' alt="Logo" />
                            </Link>
                        </div>

                        {/* Desktop Navigation */}
                        <ul className='hidden lg:flex w-full md:w-fit font-semibold gap-x-4 xl:gap-x-10'>
                            <li className={`cursor-pointer duration-300 ${color} text-[12px] xl:text-[14px]`}>
                                <Link to="/" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button className="tracking-[.02em] hover:opacity-70">Home</button>
                                    </p>
                                </Link>
                            </li>
                            <li className={`cursor-pointer duration-300 ${color} text-[12px] xl:text-[14px]`}>
                                <ScrollLink to="about" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button className="tracking-[.02em] hover:opacity-70">About Us</button>
                                    </p>
                                </ScrollLink>
                            </li>
                            <li className={`cursor-pointer duration-300 ${color} text-[12px] xl:text-[14px]`}>
                                <ScrollLink to="services" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button className="tracking-[.02em] hover:opacity-70">Services</button>
                                    </p>
                                </ScrollLink>
                            </li>
                            <li className={`cursor-pointer duration-300 ${color} text-[12px] xl:text-[14px]`}>
                                <ScrollLink to="gallery" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button className="tracking-[.02em] hover:opacity-70">Project Gallery</button>
                                    </p>
                                </ScrollLink>
                            </li>
                            <li className={`cursor-pointer duration-300 ${color} text-[12px] xl:text-[14px]`}>
                                <ScrollLink to="contact" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button className="tracking-[.02em] hover:opacity-70">Contact</button>
                                    </p>
                                </ScrollLink>
                            </li>
                        </ul>

                        <div className='cursor-pointer ml-24  md:mt-0 md:ml-0 w-full md:w-fit'>
                            
                            <a href="https://api.whatsapp.com/send?phone=9945867287" target="_blank" rel="noopener noreferrer" className="">
                                <button className='font-semibold  hover:text-white md:tracking-[1px] px-2.5 md:px-6 py-1.5 md:py-2.5 uppercase  rounded-sm md:rounded-md  hover:border
                                 hover:border-gray-200 bg-[#2384b3]  hover:bg-[#ff8c42] duration-300  text-[10px] md:text-[12px] text-white flex items-center gap-x-1'> <span className='hidden md:flex'>Make an </span>  Appointment </button>
                            </a>
                        </div>

                        {/* Mobile Navigation Icon */}
                        <div onClick={handleNav} className={`block lg:hidden  ${color}`}>
                            {nav ? <AiOutlineClose className='w-16 sm:w-16 md:w-20 ' /> : <AiOutlineMenu className='w-16 sm:w-16 md:w-20 ' />}
                        </div>

                        {/* Mobile Navigation Menu */}
                        <ul className={
                            nav
                                ? 'fixed lg:hidden left-0 top-0 w-[60%] h-[100%] border-r bg-gray-50 ease-in-out duration-500'
                                : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
                        }>
                            <Link to='/' onClick={topPage} className='w-full'>
                                <img src={black} className='w-28 sm:w-40 pt-10 ml-10' alt="Mobile Logo" onClick={handleNav} />
                            </Link>

                            <li className='ml-10 cursor-pointer duration-300 text-gray-700 text-[13px] mt-8'>
                                <ScrollLink to="/" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button className="font-semibold tracking-[.02em] hover:opacity-70" onClick={handleNav}>Home</button>
                                    </p>
                                </ScrollLink>
                            </li>

                            <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                <ScrollLink to="about" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>About Us</button>
                                    </p>
                                </ScrollLink>
                            </li>

                            <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                <ScrollLink to="services" smooth={true} duration={500} onClick={topPage}>

                                    <p className="relative group flex items-center">
                                        <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Services</button>
                                    </p>
                                </ScrollLink >

                            </li>

                            <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                <ScrollLink to="gallery" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Project Gallery</button>
                                    </p>
                                </ScrollLink>
                            </li>

                            <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                <ScrollLink to="contact" smooth={true} duration={500} onClick={topPage}>

                                    <p className="relative group flex items-center">
                                        <button class="font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Contact</button>
                                    </p>
                                </ScrollLink>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
