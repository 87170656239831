import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "-rotate-[150deg]" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const FAQ = () => {
    const [open, setOpen] = useState(null);

    const handleOpen = (value) => {
        setOpen(open === value ? null : value);
    };

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className="bg-transparent   px-6 w-full md:w-[85%] mx-auto font-[poppins]">
            <div>
                <div className="flex flex-col justify-center items-center mb-9 md:mb-14">
                    <h5 className="text-[16px] md:text-[24px] mb-3 font-bold text-white capitalize" data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000">
                        frequently asked question
                    </h5>
                    <p className="text-[10px] sm:text-[14px] md:text-[16px] md:mb-6 font-normal text-blue-gray-100 w-full md:w-[70%] text-center text-gray-50" data-aos='fade-up' data-aos-delay="100" data-aos-duration="1000">
                        Explore our FAQ section for quick answers to commonly asked questions about our program.
                    </p>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-24 gap-y-8 md:gap-y-20">

                <div data-aos='fade-down-right' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  rounded-md cursor-pointer drop-shadow-md" onClick={() => handleOpen(1)}>
                        <div className="flex justify-between items-center text-[11px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What types of concrete do you offer?
                            <Icon id={1} open={open} />
                        </div>
                        {open === 1 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-md px-3">
                                <div className="flex flex-col gap-y-1">
                                    <p className="text-gray-800  text-[11px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                        We provide a range of concrete solutions, including Ready-Mix Concrete (RMC), M7.5, M10, M15, M20, M25, and M30 mixes, each designed to meet specific construction needs.
                                    </p>

                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  rounded-md cursor-pointer drop-shadow-md" onClick={() => handleOpen(2)}>
                        <div className="flex justify-between items-center text-[11px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            How can I place an order for concrete?
                            <Icon id={2} open={open} />
                        </div>
                        {open === 2 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-md px-3">
                                <p className="text-gray-800 text-[11px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    To place an order, simply contact us via phone or email with your project details and requirements. Our team will guide you through the process and ensure timely delivery.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-right' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  rounded-md cursor-pointer drop-shadow-md" onClick={() => handleOpen(3)}>
                        <div className="flex justify-between items-center text-[11px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What is the minimum quantity of concrete I can order?
                            <Icon id={3} open={open} />
                        </div>
                        {open === 3 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-md px-3">
                                <p className="text-gray-800 text-[11px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    We accommodate both small and large orders. Please contact us to discuss your specific needs, and we’ll assist you with the appropriate quantity.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  rounded-md cursor-pointer drop-shadow-md" onClick={() => handleOpen(4)}>
                        <div className="flex justify-between items-center text-[11px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            How is the quality of your concrete ensured?
                            <Icon id={4} open={open} />
                        </div>
                        {open === 4 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-md px-3">
                                <p className="text-gray-800 text-[11px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Our concrete is produced under strict quality control measures to ensure consistency and durability. We use advanced equipment and adhere to industry standards for all our mixes.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


                <div data-aos='fade-down-right' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  rounded-md cursor-pointer drop-shadow-md" onClick={() => handleOpen(5)}>
                        <div className="flex justify-between items-center text-[11px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Can you provide concrete for residential and commercial projects?
                            <Icon id={5} open={open} />
                        </div>
                        {open === 5 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-md px-3">
                                <p className="text-gray-800 text-[11px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Yes, we supply concrete for both residential and commercial projects. Whether you need concrete for a
                                    small home improvement or a large-scale commercial development, we’ve got you covered.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  rounded-md cursor-pointer drop-shadow-md" onClick={() => handleOpen(6)}>
                        <div className="flex justify-between items-center text-[11px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What steps do you take to minimize environmental impact?
                            <Icon id={6} open={open} />
                        </div>
                        {open === 6 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-md px-3">
                                <p className="text-gray-800 text-[11px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    We prioritize environmentally friendly practices by reducing dust and noise pollution, using bulk cement
                                    to cut down on waste, and ensuring efficient transportation methods to lower our carbon footprint.
                                </p>
                            </div>
                        )}
                    </div>
                </div>



            </div>
        </div>
    );
};

export default FAQ;
