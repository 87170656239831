import React from 'react'

// import logo from '../images/64.png'
import logo from '../images/white_logo-2.png'


//icons
import { FiInstagram } from "react-icons/fi";
import { FaSquareXTwitter } from "react-icons/fa6";
import { ImWhatsapp } from "react-icons/im";
import { PiLinkedinLogoBold } from "react-icons/pi";
import { LuFacebook } from "react-icons/lu";
import { FaThreads } from "react-icons/fa6";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { TbDeviceLandlinePhone } from "react-icons/tb";


import { MdOutlineMail } from "react-icons/md";
import { FaMobileScreen } from "react-icons/fa6";

import { Link } from 'react-router-dom';
import {Link as ScrollLink} from 'react-scroll';


const Footer = () => {

    function topPage() {
        window.scroll(0, 0)
    }

    return (
        <>

            <div className='w-full'>
                
                <div class="bg-gray-900 font-[poppins] rounded-t-md pt-4 md:pt-8">
                    <div class="px-4  w-full  md:w-[85%] mx-auto md:px-4 sm:px-6 text-gray-800 sm:grid md:grid-cols-4 sm:grid-cols-2">

                        <div class=" p-2 md:p-4">
                            <img src={logo} className=' w-20 sm:w-28 md:w-32 opacity-90 object-cover drop-shadow-lg' alt="" />
                        </div>
                        
                        <div className="p-2 md:p-4">
                            <div className="text-[14px] sm:text-[15px] md:text-[16px] uppercase text-sky-700 font-bold">Quick Links</div>
                            <ScrollLink className="text-gray-200 my-3 block text-[12px] sm:text-[13px] md:text-[14px] cursor-pointer" to="" smooth={true} duration={500}>
                                Home
                            </ScrollLink>
                            <ScrollLink className="text-gray-200 my-3 block text-[12px] sm:text-[13px] md:text-[14px] cursor-pointer" to="services" smooth={true} duration={500}>
                                Services
                            </ScrollLink>
                            <ScrollLink className="text-gray-200 my-3 block text-[12px] sm:text-[13px] md:text-[14px] cursor-pointer" to="gallery" smooth={true} duration={500}>
                                Projects
                            </ScrollLink>
                            <ScrollLink className="text-gray-200 my-3 block text-[12px] sm:text-[13px] md:text-[14px] cursor-pointer" to="contact" smooth={true} duration={500}>
                                Contact
                            </ScrollLink>
                        </div>


                        <div class="p-2 md:p-4">
                            <div class="text-[14px] sm:text-[15px] md:text-[16px]  uppercase text-sky-700 font-bold">Support</div>
                            <a class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#">Help Center </a>
                            <Link to='privacy-policy' onClick={topPage}>
                                <a class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#">Privacy Policy </a>
                            </Link>

                            <Link to='terms-conditions' onClick={topPage}>
                                <a class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#">Terms & Conditions</a>
                            </Link>


                        </div>

                        <div class="p-2 md:p-4">
                            <div class="text-[14px] sm:text-[15px] md:text-[16px]  uppercase text-sky-700 font-bold">Contact us</div>

                            <p class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" > 19(A), Kellamballi Industrial Area, Badanaguppe, Chamarajanagar  571313 </p>
                            <p class="text-gray-200 my-3   text-[12px] sm:text-[13px]  md:text-[14px] flex items-center gap-x-2" href="/#"><MdOutlineMail className=' text-[16px] md:text-[20px] font-bold' />  ssrrmc2018@gmail.com</p>
                            <p className='text-gray-200 text-[12px] sm:text-[13px] md:text-[14px] my-3 flex items-center gap-x-2 '><MdOutlinePhoneAndroid className='text-[16px] md:text-[20px] font-bold' /> +91 9945867287</p>

                        </div>


                    </div>
                </div>


                <div class="bg-gray-900 md:pt-2">
                    <div class="flex flex-col  justify-between md:flex-row-reverse pb-2 md:pb-5 px-3 m-auto pt-5 border-t text-gray-800 text-sm  w-[80%]  items-center">


                        <div class="mt-1 md:mt-2 flex-col  flex gap-x-4 md:gap-x-8 ">


                            <div className='mt-1 md:mt-2 flex-row  flex gap-x-4 md:gap-x-8 '>
                                <Link to='' target='_blank' >
                                    <div className='w-6 h-6 md:w-9 md:h-9 bg-white hover:border hover:border-gray-300 hover:bg-[#fbfbfb] rounded-full flex justify-center items-center shadow-md drop-shadow-md  cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                        <FaSquareXTwitter className='text-[12px] sm:text-[13px] md:text-[16px] text-gray-800' />
                                    </div>
                                </Link>

                                <Link to="" target='_blank'>

                                    <div className='w-6 h-6 md:w-9 md:h-9 bg-white hover:border hover:border-gray-300 hover:bg-[#fbfbfb] rounded-full flex justify-center items-center shadow-md drop-shadow-md  cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                        <FiInstagram className='text-[12px] sm:text-[13px] md:text-[16px] text-gray-800' />
                                    </div>
                                </Link>

                                <Link to="https://api.whatsapp.com/send?phone=" target='_blank' className="">
                                    <div className='w-6 h-6 md:w-9 md:h-9 bg-white hover:border hover:border-gray-300 hover:bg-[#fbfbfb] rounded-full flex justify-center items-center shadow-md drop-shadow-md  cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                        <ImWhatsapp className='text-[12px] sm:text-[13px] md:text-[16px] text-gray-800' />
                                    </div>
                                </Link>

                                <Link to="" target='_blank' className="">
                                    <div className='w-6 h-6 md:w-9 md:h-9 bg-white hover:border hover:border-gray-300 hover:bg-[#fbfbfb] rounded-full flex justify-center items-center shadow-md drop-shadow-md  cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                        <PiLinkedinLogoBold className='text-[12px] sm:text-[13px] md:text-[16px] text-gray-800' />
                                    </div>
                                </Link>

                                <Link to='' target='_blank'>

                                    <div className='w-6 h-6 md:w-9 md:h-9 bg-white hover:border hover:border-gray-300 hover:bg-[#fbfbfb] rounded-full flex justify-center items-center shadow-md drop-shadow-md cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                        <LuFacebook className='text-[12px] sm:text-[13px] md:text-[16px] text-gray-800' />
                                    </div>
                                </Link>

                                <Link to="" target='_blank'>
                                    <div className='w-6 h-6 md:w-9 md:h-9 bg-white hover:border hover:border-gray-300 hover:bg-[#fbfbfb] rounded-full flex justify-center items-center shadow-md drop-shadow-md cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                        <FaThreads className='text-[12px] sm:text-[13px] md:text-[16px] text-gray-800' />
                                    </div>
                                </Link>
                            </div>


                        </div>

                        <div class=" my-2 md:my-5 text-[10px] sm:text-[12px] md:text-[14px] text-gray-100"> © Sri SaiRam 2024. All Rights Reserved.</div>

                    </div>
                </div>
            </div>



        </>
    )
}

export default Footer
