import React, { useEffect } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import './animation.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import TextScramble from '../compontens/TextScramble';
import ContactForm from '../compontens/ContactForm';

import logo from '../images/white_logo.png'
import ganesh_log from '../images/sai_ganesh_concerete_logo.png'
import blueLogo from '../images/blue_logo.png'

import banner from '../images/banner/banner.png'
import videobanner from '../images/rmc/video.mp4'
import readymix from '../images/readymix.png'

import highOne from '../images/high/highOne.png'
import highTwo from '../images/high/highTwo.png'
import highThree from '../images/high/highThree.png'
import highFour from '../images/high/highfour.png'
import highFive from '../images/high/highfive.png'
import highSix from '../images/high/highsix.png'
import highSeven from '../images/high/highseven.png'

import why from '../images/why.png'

import imgOne from '../images/banner/7.jpg'
import imgTwo from '../images/banner/8.jpg'
import imgThree from '../images/banner/9.jpg'
import imgFour from '../images/banner/10.jpg'

import about from '../images/home_about.png'

import contact from '../images/contact.png'

//icons
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLocationOn } from "react-icons/md";


import { TbEyeSearch } from "react-icons/tb";
import { GiBullseye } from "react-icons/gi";
import { FaHandsHoldingChild } from "react-icons/fa6";




const Home = () => {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        cssEase: "linear"
    };


    const text = "OUR HIGHLIGHTS";

    const project = "Project Gallery"


    // highlights

    const settingsOne = {
        className: "center",
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15px",
                }
            }
        ]
    };


    function topPage() {
        window.scroll(0.0)
    }


    return (
        <>

            <div className='overflow-hidden'>

                <div>
                    <img src={banner} className='fixed top-0 w-full h-screen -z-30 object-cover' alt="" />
                </div>

                <div>
                    <div className='fixed top-0 w-full h-screen -z-20 object-cover bg-black/55'></div>
                </div>

                {/* <section>
                    <div>
                        <div className="slider-container font-[Cardo]">
                            <Slider {...settings}>

                                <div>
                                    <div className='relative'>
                                        <div className='relative w-full overflow-hidden'>
                                            <img src={banner} className='w-full h-full object-cover' alt="" />
                                            <div className='absolute inset-0   z-10'></div>
                                        </div>

                                    </div>
                                </div>


                                <div>
                                    <div className='relative'>
                                        <div className='relative w-full overflow-hidden'>
                                            <img src={banner} className='w-full h-full object-cover' alt="" />
                                            <div className='absolute inset-0   z-10'></div>
                                        </div>

                                    </div>
                                </div>

                                <div>
                                    <div className='relative'>
                                        <div className='relative w-full overflow-hidden'>
                                            <img src={banner} className='w-full h-full object-cover' alt="" />
                                            <div className='absolute inset-0   z-10'></div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='relative'>
                                        <div className='relative w-full overflow-hidden'>
                                            <img src={banner} className='w-full h-full object-cover' alt="" />
                                            <div className='absolute inset-0   z-10'></div>
                                        </div>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </section> */}

                {/* ------ video banner ------- */}

                <section>
                    <div>

                        <video className='w-full object-cover ' autoPlay muted loop playsInline >
                            <source src={videobanner} type="video/mp4" />
                        </video>

                    </div>
                </section>

                {/* ------- About ------ */}

                <section className='bg-[#ebecf0] font-[poppins]' id='about' >
                    <div className='pt-7 md:pt-14 pb-10 md:pb-14  font-[]'>

                        <div className='flex flex-col-reverse md:flex-row items-center gap-y-4 gap-x-20 px-4 md:px-0 w-full md:w-[80%] mx-auto'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center items-center ' data-aos='fade-right' data-aos-duration="2000" >

                                <div className='w-full'>
                                    <img src={about} className='w-full md:w-full object-cover rounded-md ' alt="" />
                                </div>
                            </div>

                            <div className='w-full md:w-[50%]' data-aos='fade-left'>

                                <h3 className='font-bold text-[14px] sm:text-[14px]  md:text-[16px] uppercase  text-start text-sky-700' data-aos='fade-left' data-aos-delay="70" data-aos-duration="2000"  > About US </h3>
                                <h3 className='font-bold text-[23px] sm:text-[22px]  md:text-[30px] uppercase text-gray-600 text-start' data-aos='fade-left' data-aos-delay="70" data-aos-duration="2000"  > Sri SaiRam  </h3>

                                <p className='font-medium mt-2 sm:mt-3 md:mt-5 text-gray-600 text-[12px] sm:text-[14px] md:text-[14px]  leading-6' data-aos='fade-left' data-aos-delay="70" data-aos-duration="3000">
                                    Sri Sairam Ready Mix Concrete established in 2017, in Badanaguppe, Chamarajanagar, is a foremost provider in the region. It is situated near Biligiri Granites and it is within reach
                                    for local and visiting customers.
                                </p>
                                <p className='font-medium mt-1 sm:mt-2 md:mt-3 text-gray-600 text-[12px] sm:text-[14px] md:text-[14px] leading-6' data-aos='fade-left' data-aos-delay="70" data-aos-duration="3000">
                                    Sri Sairam aims to increase its assistance and serve a wider clientele with its devoted staff. It is famous for outstanding customer service and quality products, the business have built a
                                    strong reputation and an emerging customer base.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>


                {/* -------- mission & vision ------- */}

                <section className='bg-white pt-10 md:pt-20 pb-10 md:pb-20 font-[poppins]'>
                    <div className='w-full px-4 md:w-[90%] mx-auto'>

                        <div className='flex justify-center items-center'>

                            <div className='grid grid-cols-1 md:grid-cols-3 gap-y-8  divide-y md:divide-x-2 md:divide-y-0 divide-sky-900/50  md:gap-x-3'>

                                <div data-aos='fade-up-right' data-aos-duratoin='3000'>
                                    <div className='flex flex-col items-center gap-y-4 justify-center'>

                                        <div>
                                            <GiBullseye className='text-[68px] font-bold text-sky-900' />
                                        </div>

                                        <div className='flex flex-col items-center justify-center gap-y-2 md:gap-y-3'>
                                            <h1 className='font-bold text-[14px] md:text-[18px] uppercase text-sky-700'> <span className='text-gray-700'>Our</span> Mission</h1>
                                            <p className='px-6 md:px-8 text-center font-[roboto] text-[10px] md:text-[12px] text-gray-600 drop-shadow-lg'>
                                                We deliver reliable, sustainable ready-mix concrete for the construction industry. Focused on precision and durability, we ensure top quality in every project.
                                                Our goal is to build a stronger, eco-friendly future.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div data-aos='fade-up' data-aos-duratoin='3000'>
                                    <div className='flex flex-col items-center gap-y-4 justify-center pt-4 md:pt-0'>

                                        <div>
                                            <TbEyeSearch className='text-[68px] font-bold text-sky-900' />
                                        </div>

                                        <div className='flex flex-col items-center justify-center gap-y-2 md:gap-y-3'>
                                            <h1 className='font-bold text-[14px] md:text-[18px] uppercase text-sky-700'>  <span className='text-gray-700'>Our</span> Vision</h1>
                                            <p className='px-6 md:px-8 text-center font-[roboto] text-[11px]  md:text-[12px] text-gray-600 drop-shadow-lg'>
                                                We envision a world where our concrete helps build stronger, safer, and more resilient communities, fostering growth and progress while minimizing environmental impact.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div data-aos='fade-up-left' data-aos-duratoin='3000'>
                                    <div className='flex flex-col items-center gap-y-4 justify-center pt-4 md:pt-0'>

                                        <div>
                                            <FaHandsHoldingChild className='text-[68px] font-bold text-sky-900' />
                                        </div>

                                        <div className='flex flex-col items-center justify-center gap-y-2 md:gap-y-3'>
                                            <h1 className='font-bold text-[14px] md:text-[18px] uppercase text-sky-700'>  <span className='text-gray-700'>Our</span> Values</h1>
                                            <p className='px-6 md:px-8 text-center font-[roboto] text-[11px]  md:text-[12px] text-gray-600 drop-shadow-lg'>
                                                We are dedicated to upholding the highest level of reliability in all our actions and decisions. Integrity guides our business, ensuring honesty in every transaction.
                                                Above all, we prioritize the safety and wellbeing of our people.
                                            </p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                {/* All Services */}

                <section className='bg-black/70 pt-5 pb-6 md:py-10 font-[poppins] w-full' id='services'>
                    <div className='px-2 md:px-0 w-full  md:w-[85%] mx-auto flex flex-col justify-center items-center'>

                        <div className='flex flex-col  items-center justify-center'>
                            <div>
                                <TextScramble />
                            </div>
                            <p className=' md:mt-2 text-center text-[12px] sm:text-[14px] md:text-[15px] text-white'>To become a trendsetter in the industry, Learn the trending course of the industry.</p>
                        </div>

                        <div className='w-full  px-3 md:px-0'>
                            <div className='grid grid-col-1 md:grid-cols-2 gap-y-10 mt-6 md:mt-14 md:ml-24'>

                                <div class="relative overflow-hidden w-full md:w-[80%] h-full  rounded-lg md:rounded-3xl cursor-pointer font-bold bg-white border border-gray-200 hover:scale-105 duration-300" data-aos="fade-up-right">
                                    <Link to='/sairam_rmc' onClick={topPage} class="block w-full h-full px-6  py-3 md:py-5">
                                        <div class="z-10 absolute w-full h-full"></div>
                                        <div class="absolute peer-hover:-top-20 peer-hover:-left-16 peer-hover:w-[140%] peer-hover:h-[140%] -top-32 -left-16 w-32 h-44 rounded-full bg-[#f0f8ff] transition-all duration-500"></div>
                                        <div class="absolute flex text-xl text-center items-end justify-end peer-hover:right-0 peer-hover:rounded-b-none peer-hover:bottom-0 peer-hover:items-center peer-hover:justify-center peer-hover:w-full peer-hover:h-full -bottom-32 -right-16 w-36 h-44 rounded-full bg-[#f0f8ff] transition-all duration-500">
                                            Nice to meet u,<br />
                                        </div>
                                        <div class="w-full h-full items-center justify-center flex transition duration-500 ease-in-out transform hover:scale-105 hover:text-white hover:bg-purple-600">
                                            <div class='flex flex-col h-full items-center justify-center'>
                                                <div>
                                                    <img src={logo} class='w-20 md:w-32 object-cover drop-shadow-md' alt="" />
                                                </div>
                                                <div class='mt-2 mb-3'>
                                                    <h3 class='mb-1 uppercase text-[14px] sm:text-[16px] md:text-[20px] text-center font-semibold text-sky-950'>SaiRam RMC</h3>
                                                    <p class='text-center text-[11px] sm:text-[12px] md:text-[12px] text-gray-800 font-normal font-[roboto]'>
                                                        Our ready-mix concrete delivers precision, strength, and durability for any project. From homes to large infrastructure, it ensures reliable, long-lasting results with every pour. Built to withstand the test of time.
                                                    </p>
                                                </div>
                                                <button class='hover:bg-transparent bg-sky-700 px-5 md:px-6 py-1.5 md:py-2  font-[roboto] flex items-center gap-x-1 rounded-md text-white hover:scale-105 text-[11px] md:text-[12px]'>Get Details</button>

                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div class="relative overflow-hidden w-full md:w-[80%] h-full  rounded-lg md:rounded-3xl cursor-pointer font-bold bg-white border border-gray-200 hover:scale-105 duration-300" data-aos="fade-up-left">
                                    <Link to='/sai_ganesh_concrete_products' onClick={topPage} class="block w-full h-full px-6 py-3 md:py-5">
                                        <div class="z-10 absolute w-full h-full"></div>
                                        <div class="absolute peer-hover:-top-20 peer-hover:-left-16 peer-hover:w-[140%] peer-hover:h-[140%] -top-32 -left-16 w-32 h-44 rounded-full bg-[#f0f8ff] transition-all duration-500"></div>
                                        <div class="absolute flex text-xl text-center items-end justify-end peer-hover:right-0 peer-hover:rounded-b-none peer-hover:bottom-0 peer-hover:items-center peer-hover:justify-center peer-hover:w-full peer-hover:h-full -bottom-32 -right-16 w-36 h-44 rounded-full bg-[#f0f8ff] transition-all duration-500">
                                            Nice to meet u,<br />
                                        </div>
                                        <div class="w-full h-full items-center justify-center flex transition duration-500 ease-in-out transform hover:scale-105 hover:text-white hover:bg-purple-600">
                                            <div class='flex flex-col h-full items-center justify-center'>
                                                <div>
                                                    <img src={ganesh_log} class='w-20 md:w-32 object-cover drop-shadow-md' alt="" />
                                                </div>
                                                <div class='mt-3 mb-3'>
                                                    <h3 class='mb-1 uppercase text-[14px] sm:text-[16px] md:text-[20px] text-center font-semibold text-sky-950'>Sai Ganesh Concrete products </h3>
                                                    <p class='text-center text-[11px] sm:text-[12px] md:text-[12px] text-gray-800 font-normal font-[roboto]'>
                                                        We offer a wide range of high-quality concrete products to suit various construction needs. Our products are designed to provide durability, strength, and aesthetic appeal to any project.
                                                    </p>
                                                </div>
                                                <button class='bg-sky-700 px-5 md:px-6 py-1.5 md:py-2 font-[roboto] flex items-center gap-x-1 rounded-md text-white hover:scale-105 text-[11px] md:text-[12px]'>Get Details </button>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div class="relative overflow-hidden w-full md:w-[80%] h-full rounded-lg md:rounded-3xl cursor-pointer font-bold bg-white border border-gray-200 hover:scale-105 duration-300" data-aos="fade-up-left">
                                    <Link to='/sai_ganesh_blue_sand' onClick={topPage} class="block w-full h-full px-6 py-3 md:py-5">
                                        <div class="z-10 absolute w-full h-full"></div>
                                        <div class="absolute peer-hover:-top-20 peer-hover:-left-16 peer-hover:w-[140%] peer-hover:h-[140%] -top-32 -left-16 w-32 h-44 rounded-full bg-[#f0f8ff] transition-all duration-500"></div>
                                        <div class="absolute flex text-xl text-center items-end justify-end peer-hover:right-0 peer-hover:rounded-b-none peer-hover:bottom-0 peer-hover:items-center peer-hover:justify-center peer-hover:w-full peer-hover:h-full -bottom-32 -right-16 w-36 h-44 rounded-full bg-[#f0f8ff] transition-all duration-500">
                                            Nice to meet u,<br />
                                        </div>
                                        <div class="w-full h-full items-center justify-center flex transition duration-500 ease-in-out transform hover:scale-105 hover:text-white hover:bg-purple-600">
                                            <div class='flex flex-col items-center justify-center'>
                                                <div>
                                                    <img src={blueLogo} class='w-24 md:w-36 object-cover drop-shadow-md' alt="" />
                                                </div>
                                                <div class='mt-3 mb-3'>
                                                    <h3 class='mb-1 uppercase text-[14px] sm:text-[16px] md:text-[20px] text-center font-semibold text-sky-950'>Sai Ganesh Blue Sand</h3>
                                                    <p class='text-center text-[11px] sm:text-[12px] md:text-[12px] font-normal text-gray-800  font-[roboto]'>

                                                        We supply premium blue sand and aggregates for construction, landscaping, and water filtration, sourced from top quarries and processed for exceptional quality and consistency.
                                                    </p>
                                                </div>
                                                <button class='bg-sky-700 px-5 md:px-6 py-1.5 md:py-2 font-[roboto] flex items-center gap-x-1 rounded-md text-white hover:scale-105 text-[11px] md:text-[12px]'>Get Details </button>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                {/* our highlights */}

                <section className='bg-white pt-6 md:pt-10 pb-0 md:pb-24' >
                    <div className='px-6 md:ppx-0 w-full md:w-[85%] mx-auto'>

                        <div>
                            <div class="mb-1 sm:mb-8 md:mb-10 flex flex-col items-center font-[poppins]">
                                {/* <h2 class=" text-center text-[20px] sm:text-[24px] md:text-[32px] font-bold text-gray-800 uppercase " data-aos='fade-up' data-aos-duration="1000">
                                    Our Highlights
                                </h2> */}

                                <div className="w-max" data-aos='fade-up' data-aos-duration="1000">
                                    {text.split('').map((char, index) => (
                                        <span
                                            key={index}
                                            className="animate-wave text-center text-[20px] sm:text-[24px] md:text-[32px] font-bold text-sky-700 uppercase"
                                            style={{ animationDelay: `${index * 0.1}s` }}
                                        >
                                            {char === ' ' ? '\u00A0' : char}
                                        </span>
                                    ))}
                                </div>

                                <div className='border-container flex justify-center items-center border md:border-2 rounded-[450%] md:rounded-[250%]  border-sky-800 w-24 sm:w-28 md:w-32' data-aos='fade-up' data-aos-duration="2000" data-aos-delay="50"></div>
                                <p class="text-[10px] sm:text-[13px]  md:text-[16px] mt-3 md:mt-4  text-gray-800 lg:mx-auto w-full lg:w-8/12 text-center" data-aos='fade-up' data-aos-duration="3000" data-aos-delay="70">
                                    At Sri SaiRam, we pride ourselves on delivering a comprehensive and dynamic learning experience in the field of construction and engineering excellence.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="slider-container">
                        <Slider {...settingsOne} className='px-4 py-4 md:py-8'>

                            {/* highlightss one */}

                            <div className='px-3 py-3' data-aos='fade-up' data-aos-duration="1000">
                                <div className='flex flex-col justify-center items-center hover:shadow-lg hover:drop-shadow-lg px-1 md:px-3 py-2 md:py-3'>

                                    <div className=''>
                                        {/* <FaMoneyBillWave className='bg-[#f8f8ff] w-20 h-20 border border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-2' alt="" /> */}
                                        <img src={highOne} className='w-16 sm:w-20 md:w-24 object-cover ' alt="" />
                                        {/* border bg-[#f8f8ff] border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-1 */}
                                    </div>

                                    <div className='mt-2'>
                                        <h3 className='text-center text-[12px] sm:text-[15px] md:text-[18px] font-bold uppercase text-gray-900'>Placing and Transportation is Easy</h3>
                                        <p className='text-center mt-2 font-medium text-[10px] sm:text-[12px] md:text-[13px] px-6 text-gray-700'>
                                            <span className='font-bold text-gray-900'>Ready-Mix Concrete</span>  (RMC) is delivered directly to the construction site, simplifying placement and reducing the time and effort required for on-site mixing and handling.

                                        </p>
                                    </div>


                                </div>
                            </div>

                            {/* highlightss two */}

                            <div className='px-3 py-3' data-aos='fade-up' data-aos-duration="1000">
                                <div className='flex flex-col justify-center items-center hover:shadow-lg hover:drop-shadow-lg px-1 md:px-3 py-2 md:py-3'>

                                    <div className=''>
                                        {/* <FaMoneyBillWave className='bg-[#f8f8ff] w-20 h-20 border border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-2' alt="" /> */}
                                        <img src={highTwo} className='w-16 sm:w-20 md:w-24 object-cover ' alt="" />
                                        {/* border bg-[#f8f8ff] border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-1 */}
                                    </div>

                                    <div className='mt-2'>
                                        <h3 className='text-center text-[12px] sm:text-[15px] md:text-[18px] font-bold uppercase text-gray-900'>Dust Pollution is Reduced</h3>
                                        <p className='text-center mt-2 font-medium text-[10px] sm:text-[12px] md:text-[13px] px-6 text-gray-700'>
                                            RMC significantly lowers dust emissions compared to traditional mixing methods, improving air quality and creating a cleaner work environment.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* highlightss three */}

                            <div className='px-3 py-3' data-aos='fade-up' data-aos-duration="1000">
                                <div className='flex flex-col justify-center items-center hover:shadow-lg hover:drop-shadow-lg px-1 md:px-3 py-2 md:py-3'>

                                    <div className=''>
                                        {/* <FaMoneyBillWave className='bg-[#f8f8ff] w-20 h-20 border border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-2' alt="" /> */}
                                        <img src={highThree} className='w-16 sm:w-20 md:w-24 object-cover ' alt="" />
                                        {/* border bg-[#f8f8ff] border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-1 */}
                                    </div>

                                    <div className='mt-4'>
                                        <h3 className='text-center text-[12px] sm:text-[15px] md:text-[18px] font-bold uppercase text-gray-900'>Bulk Cement Utilization</h3>
                                        <p className='text-center mt-2 font-medium text-[10px] sm:text-[12px] md:text-[13px] px-6 text-gray-700'>
                                            By utilizing bulk cement, RMC minimizes packaging waste and ensures more efficient storage and usage, reducing the need for handling numerous small cement bags.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* highlightss four */}

                            <div className='px-3 py-3' data-aos='fade-up' data-aos-duration="1000">
                                <div className='flex flex-col justify-center items-center hover:shadow-lg hover:drop-shadow-lg px-1 md:px-3 py-2 md:py-3'>

                                    <div className=''>
                                        {/* <FaMoneyBillWave className='bg-[#f8f8ff] w-20 h-20 border border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-2' alt="" /> */}
                                        <img src={highFour} className='w-16 sm:w-20 md:w-24 object-cover ' alt="" />
                                        {/* border bg-[#f8f8ff] border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-1 */}
                                    </div>

                                    <div className='mt-2'>
                                        <h3 className='text-center text-[12px] sm:text-[15px] md:text-[18px] font-bold uppercase text-gray-900'>High Speed of Construction</h3>
                                        <p className='text-center mt-2 font-medium text-[10px] sm:text-[12px] md:text-[13px] px-6 text-gray-700'>
                                            The consistent quality and quick availability of RMC accelerate the overall construction process, helping projects stay on schedule and reduce delays.
                                        </p>
                                    </div>

                                </div>
                            </div>

                            {/* highlightss five */}

                            <div className='px-3 py-3' data-aos='fade-up' data-aos-duration="1000">
                                <div className='flex flex-col justify-center items-center hover:shadow-lg hover:drop-shadow-lg px-1 md:px-3 py-2 md:py-3'>

                                    <div className=''>
                                        {/* <FaMoneyBillWave className='bg-[#f8f8ff] w-20 h-20 border border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-2' alt="" /> */}
                                        <img src={highFive} className='w-16 sm:w-20 md:w-24 object-cover ' alt="" />
                                        {/* border bg-[#f8f8ff] border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-1 */}
                                    </div>

                                    <div className='mt-2'>
                                        <h3 className='text-center text-[12px] sm:text-[15px] md:text-[18px] font-bold uppercase text-gray-900'>Reduced Noise and Air Pollution</h3>
                                        <p className='text-center mt-2 font-medium text-[10px] sm:text-[12px] md:text-[13px] px-6 text-gray-700'>
                                            RMC reduces the need for noisy on-site mixing and machinery, contributing to quieter job sites while lowering harmful emissions in the air.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* highlightss six */}

                            <div className='px-3 py-3' data-aos='fade-up' data-aos-duration="3000" data-aos-delay="70">
                                <div className='flex flex-col justify-center items-center hover:shadow-lg hover:drop-shadow-lg px-1 md:px-3 py-2 md:py-3'>

                                    <div className=''>
                                        {/* <FaMoneyBillWave className='bg-[#f8f8ff] w-20 h-20 border border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-2' alt="" /> */}
                                        <img src={highSix} className='w-16 sm:w-20 md:w-24 object-cover ' alt="" />
                                    </div>

                                    <div className='mt-2'>
                                        <h3 className='text-center text-[12px] sm:text-[15px] md:text-[18px] font-bold uppercase text-gray-900' >Environment Pollution is Reduced</h3>
                                        <p className='text-center mt-2 font-medium text-[10px] sm:text-[12px] md:text-[13px] px-6 text-gray-700'>
                                            The controlled production of RMC ensures less environmental impact through reduced wastage of materials and minimal pollution during transportation and application.
                                        </p>
                                    </div>
                                </div>
                            </div>


                            {/* highlightss seven */}

                            <div className='px-3 py-3' data-aos='fade-up' data-aos-duration="3000" data-aos-delay="70">
                                <div className='flex flex-col justify-center items-center hover:shadow-lg hover:drop-shadow-lg px-1 md:px-3 py-2 md:py-3'>

                                    <div className=''>
                                        {/* <FaMoneyBillWave className='bg-[#f8f8ff] w-20 h-20 border border-gray-300 drop-shadow-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-full p-2' alt="" /> */}
                                        <img src={highSeven} className='w-16 sm:w-20 md:w-24 object-cover ' alt="" />
                                    </div>

                                    <div className='mt-2'>
                                        <h3 className='text-center text-[12px] sm:text-[15px] md:text-[18px] font-bold uppercase text-gray-900' >Merits of Ready-Mix Concrete</h3>
                                        <p className='text-center mt-2 font-medium text-[10px] sm:text-[12px] md:text-[13px] px-6 text-gray-700'>
                                            Ready-Mix Concrete ensures consistent quality, faster construction, and easy placement. It reduces pollution and waste,
                                            making it a more efficient and environmentally friendly option.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </section>


                {/* comparison */}
                <section className='font-[roboto]' >
                    <div class="inset-0 py-8 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
                        <div className='px-4 md:px-0 w-full md:w-[80%] mx-auto'>
                            <div className='flex flex-col-reverse md:flex-row items-center gap-y-2 gap-x-14'>

                                <div className='w-full md:w-[50%] mx-auto' data-aos='fade-right'>
                                    <img src={why} className='w-full md:w-[300%] object-cover' alt="" />
                                </div>

                                <div className='w-full md:w-[50%] mx-auto' data-aos='fade-left'>
                                    <h3 className='font-semibold md:text-[32px] text-center uppercase text-gray-600'>Why Choose <span className='text-sky-700'>Sri Sairam?</span> </h3>
                                    <p className='text-[11px] sm:text-[12px] md:text-[14px] font-medium text-gray-700 text-justify mt-2 md:mt-4 leading-5 md:leading-6'>
                                        At Sri Sairam, we are committed to delivering top-quality concrete solutions tailored to meet the specific needs of your projects.
                                        With a focus on customer satisfaction, efficient service, and environmentally friendly practices, we stand out for our reliability
                                        and expertise. Our advanced production methods, combined with a dedicated team, ensure that every project we undertake is built to
                                        the highest standards. Trust Sri Sairam for quality, consistency, and exceptional results in every construction endeavor.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>



                {/* ----- FAQ ------ */}

                {/* <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-sky-950'>
                    <div>
                        <FAQ />
                    </div>
                </section> */}



                {/* ----------- Project Gallery ---------- */}

                <section className='pt-6 md:pt-14  pb-10 md:pb-16 bg-sky-50 font-[poppins]' id='gallery'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col justify-center items-center  gap-y-1 md:gap-y-3'>
                            <div className="w-max" data-aos='fade-up' data-aos-duration="1000">
                                {project.split('').map((char, index) => (
                                    <span
                                        key={index}
                                        className="animate-wave text-center text-[20px] sm:text-[24px] md:text-[32px] font-bold text-sky-700 uppercase"
                                        style={{ animationDelay: `${index * 0.1}s` }}
                                    >
                                        {char === ' ' ? '\u00A0' : char}
                                    </span>
                                ))}
                            </div>
                            {/* <h2 className='font-bold text-[20px] md:text-[28px] text-center uppercase'>Project Gallery</h2> */}
                            <p className='text-[10px] md:text-[14px] text-center text-gray-700 font-[roboto]'>Explore our diverse portfolio of completed projects, showcasing our expertise in delivering high-quality construction solutions. From residential buildings to
                                commercial structures, each project highlights our commitment to excellence, precision, and customer satisfaction.
                            </p>
                        </div>

                        <div className='flex justify-center items-center mt-5 md:mt-10'>

                            <div className='grid grid-cols-3 md:grid-cols-5 gap-x-3  md:gap-x-4  gap-y-3 md:gap-y-4'>

                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgOne} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgTwo} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgThree} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgFour} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl relative'>
                                        <img src={imgOne} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />

                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgTwo} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgThree} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgFour} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgOne} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center '>
                                    <div className='bg-gray-600 rounded-md overflow-hidden shadow-md drop-shadow-xl'>
                                        <img src={imgTwo} className='w-full h-full object-cover hover:scale-110 duration-300 ' alt='image' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                {/* ---------- contact ---------- */}

                <section className='bg-white py-6 md:py-10' id='contact' >
                    <div className='flex flex-col  px-4 justify-center items-center font-[poppins]'>

                        <div className='flex flex-col gap-y-1 md:gap-y-3 items-center'>
                            <h3 className='font-bold text-[20px] sm:text-[24px] md:text-[30px] text-center md:tracking-[1px] text-sky-700 uppercase' data-aos='fade-up'>Get in Touch</h3>
                            <p className='w-full md:w-[80%] mx-auto text-center text-[11px] md:text-[14px]' data-aos='fade-up'>We're here to help you with all your concrete needs. Whether you have a question about our products, services, or want to place an order, we're just a call or click away.</p>
                        </div>

                        <div className='flex flex-col md:flex-row items-start justify-center w-full   md:w-[85%]  mx-auto gap-y-10 gap-x-6 mt-6 md:mt-12'>
                            <div className='w-full flex flex-col justify-center items-start md:w-[50%] mx-auto' data-aos='fade-right'>

                                <div className='flex justify-center w-full'>
                                    <img src={contact} className=' w-[95%] md:w-[80%]  object-cover drop-shadow-md' alt='contact' />
                                </div>

                                {/* <div>
                                    <div className='w-full'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.9165469031823!2d80.24601111065365!3d13.040983813300251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267006fba7507%3A0x503e1b6f9895ffa7!2sCinema%20Factory%20Academy!5e0!3m2!1sen!2sin!4v1726470667535!5m2!1sen!2sin"
                                            className='h-[10rem] md:h-[14rem] w-full border-none focus:border-none rounded-lg shadow-sm drop-shadow-sm' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>

                                    <div className='mt-8 md:mt-16'>
                                        <div className='flex flex-col gap-y-8 md:gap-y-16'>

                                            <div className='flex items-center gap-x-8 md:gap-x-32'>
                                                <div className='flex items-center gap-x-2 md:gap-x-4 '>
                                                    <MdOutlinePhoneAndroid className='text-[16px] md:text-[20px] size-7 md:size-10 bg-sky-700 p-1.5 md:p-2.5 rounded-full text-white ' /> <span className='font-bold text-[12px]  md:text-[16px] '> +91 8787648664</span>
                                                </div>
                                                <div className='flex items-center gap-x-2 md:gap-x-4 '>
                                                    <TbDeviceLandlinePhone className='text-[16px] md:text-[20px] size-7 md:size-10 bg-sky-700 p-1.5 md:p-2.5 rounded-full text-white' /> <span className='font-bold text-[12px]  md:text-[16px]  '> 041 98343 34324</span>
                                                </div>
                                            </div>

                                            <div className='flex items-center gap-x-6 md:gap-x-32'>
                                                <div className='flex items-center gap-x-2 md:gap-x-4 '>
                                                    <HiOutlineMail className='text-[16px] md:text-[20px] size-7 md:size-10 bg-sky-700 p-1.5 md:p-2.5 rounded-full text-white ' /> <span className='font-bold text-[12px]  md:text-[16px] '> abc@gmail.com</span>
                                                </div>
                                                <div className='flex items-center  gap-x-2 md:gap-x-4 '>
                                                    <MdOutlineLocationOn className='text-[16px] md:text-[20px] size-7 md:size-10 bg-sky-700 p-1.5 md:p-2.5 rounded-full text-white' /> <span className='font-bold text-[12px]  md:text-[16px] '> Karnataka</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> */}

                            </div>


                            <div className='w-full md:w-[50%] mx-auto' data-aos='fade-left'>
                                <div className='flex justify-center items-center w-full'>

                                    <div className=" rounded-lg  w-[100%] ">
                                        <ContactForm />
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>

        </>
    )
}

export default Home
